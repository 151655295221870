<template>
  <div>
    <v-container>
      <v-card class="ma-3">
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-select
                label="Dashboards"
                v-model="board"
                :items="dashboardList"
                item-text="name"
                item-value="code"
              ></v-select>
            </v-col>
            <v-col col="8">
              <v-btn
                class="mt-2"
                color="primary darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="getEmbbedURL"
              >
                GO
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <div style="height: 100vh">
      <iframe
        id="dashboardFrame"
        width="100%"
        height="100%"
        :src="embeddedURL"
        frameborder="0"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import api from "@/services/api.js";

export default {
  data() {
    return {
      board: null,
      embeddedURL: null,
      loading: false,
    };
  },

  methods: {
    getEmbbedURL() {
      this.loading = true;
      api
        .getDashboardEmbeddedURL(this.board)
        .then((response) => {
          this.loading = false;
          this.embeddedURL = response.data.embed_url;
        })
        .catch((err) => {
          this.loading = false;
          throw err;
        });
    },
  },

  beforeMount() {
    this.$store.dispatch("dashboard/getList");
  },

  computed: {
    dashboardList() {
      return this.$store.getters["dashboard/getDashboards"];
    },
  },
};
</script>

<style scoped>
#dashboardFrame {
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px; */
}
</style>
